import React from 'react';

import SEO from 'src/components/seo';

import Step1 from 'src/features/onboarding/steps/step1';

export default function Onboarding() {
  return (
    <>
      <SEO title="Step 1" />

      <Step1 />
    </>
  );
}
